import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../components/AppContext';
import logo from './logo.png';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const { isLoggedIn, setIsLoggedIn, setUser } = useContext(AppContext);

  const handleSubmit = (e) => {
    e.preventDefault();

    
    const validUsername = process.env.REACT_APP_ADMIN;
    const validPassword = process.env.REACT_APP_PASSWORD;

    if (username === validUsername && password === validPassword) {
      setIsLoggedIn(true);
      setUser(username);
      localStorage.setItem('user', username);
      navigate('/main');
    } else {
      setError('Invalid username or password');
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-gray-100">
      <form 
        className="bg-white p-8 rounded-lg shadow-md w-96"
        onSubmit={handleSubmit}
      >
        <div className="mb-4 flex justify-center">
          <img src={logo} alt="Logo" className="h-16 w-40 object-cover" />
        </div>
        <h2 className="text-xl font-semibold mb-6 text-center">Login</h2>
        {error && <p className="text-red-500 mb-4 text-center">{error}</p>}
        <div className="mb-4">
          <label 
            htmlFor="username" 
            className="block text-sm font-medium text-gray-600"
          >
            Username:
          </label>
          <input 
            id="username"
            type="text" 
            value={username} 
            onChange={(e) => setUsername(e.target.value)} 
            className="mt-1 p-2 w-full border rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <label 
            htmlFor="password" 
            className="block text-sm font-medium text-gray-600"
          >
            Password:
          </label>
          <input 
            id="password"
            type="password" 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            className="mt-1 p-2 w-full border rounded-md"
            required
          />
        </div>
        <div className="mb-4">
          <button 
            type="submit" 
            className="w-full p-2 bg-blue-500 text-white rounded-md"
          >
            Login
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;
