// Modal.jsx
import React from 'react';

function Modal({ imageUrl, onClose }) {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center overflow-auto bg-black bg-opacity-50">
      <div className="relative w-2/2 max-w-full p-6 bg-white rounded-lg ">
        
        <img src={imageUrl} alt="Modal" className="w-auto h-[60vh]  rounded-lg object-contain" />
        <button
          className="px-4 py-2 mt-4 rounded-xl text-white bg-blue-400"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
}

export default Modal;
