import React, { useState, useEffect } from 'react';
import Sidebar from './Sidebar';
import Mainsection from './Mainsection';
import { getStorage, ref, listAll } from "firebase/storage";
import { app } from '../firebase/Firebase';
import LoadingModal from './LoadingModal'; 
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import logo from './Logo-white.png'
import { RxHamburgerMenu } from "react-icons/rx";
import { MdClose } from "react-icons/md";
function Main() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(window.innerWidth >= 1024);
  const [folders, setFolders] = useState([]);
  const [folderChange, setFolderChange] = useState();
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const storage = getStorage(app);


 
  const Logout  = ()=>{
    localStorage.removeItem('user');
    
   
    navigate('/');
  }
  const handleChangeFolder = (folderName) => {
    const folderRef = ref(storage, folderName);
    setFolderChange(folderName);
    setLoading(true); 
    listAll(folderRef)
      .then((res) => {
        const fileNames = res.items.map((itemRef) => itemRef.name);
       
        setFiles(fileNames);
      })
      .catch((error) => {
        console.error("Error listing files in folder:", error);
      })
      .finally(() => {
        setLoading(false); 
      });
  };

  const listRef = ref(storage, '/');

  useEffect(() => {
    
    const fetchFolders = async () => {
      setLoading(true);
      const listRef = ref(storage, '/');
      try {
        const res = await listAll(listRef);
        const folderNames = res.prefixes.map((folderRef) => folderRef.name);
        const fileNames = res.items.map((itemRef) => itemRef.name);
       
        setFolders(folderNames);
        setFolderChange(folderNames[0]);  
        setLoading(false);
      } catch (error) {
        console.error("Error listing items:", error);
        setLoading(false);
      }
    };

    fetchFolders();
  }, [storage]);
  const handleResize = () => {
    setIsSidebarOpen(window.innerWidth >= 1024);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    
    <div className=" bg-gray-200">
     
      {loading && <LoadingModal />}
      <div className="bg-gray-800 text-white p-4 flex flex-col sm:flex-col md:flex-row lg:flex-row xl:flex-row justify-between items-center">
        <div><img src={logo} className='h-20 w-40 object-cover' /></div>
        <div className='flex flex-col md:flex-row gap-4'>
        <button 
          className="lg:hidden"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
        >
          {isSidebarOpen ? (<MdClose size={40} className='ml-4 md:mr-4 ' />) : (<RxHamburgerMenu size={40} className='ml-4 md:mr-4' />)}
        </button>
        <button className="text-white bg-red-600 px-4 py-2 rounded-xl"  onClick={Logout}>Logout</button>

        </div>
      
      </div>
      <div className="grid grid-cols-4 ">
        <div className="col-span-4 sm:col-span-4  xl:col-span-1 lg:col-span-1">
          {isSidebarOpen && (
            <div className="md:col-span-2   md:h-screen ">
              <Sidebar folders={folders} handleFolderChange={handleChangeFolder} folderChange={folderChange}/>
            </div>
          )}
          
        </div>
        <div className='col-span-4 xl:col-span-3 lg:col-span-3  h-screen overflow-y-auto'>
            <Mainsection files={files} folderName={folderChange} handleFolderChange={handleChangeFolder}/>
          </div>
      </div>
          <ToastContainer/>
    </div>
  );
}

export default Main;
