import React from 'react';
import folderimg from './folder.png';

function Sidebar({ folders, handleFolderChange, selectedFolder, folderChange }) {
  return (
    <div className='px-1 py-4 '>
      <div className='flex flex-col gap-4'>
        {folders?.map((item, index) => {
          const isSelected = folderChange === item;

          return (
            <div 
              className={`text-lg hover:cursor-pointer hover:bg-white p-2 flex flex-row gap-2 mx-4 items-center border-b pb-2 ${isSelected ? 'bg-white' : ''}`} 
              key={index} 
              onClick={() => { handleFolderChange(item) }}
            >
              <div className="w-4 h-4">
                <img src={folderimg} alt="Folder" className="w-full h-full object-contain" />
              </div>
              <div className="flex-grow overflow-hidden">
                <h1 className='text-md'>{item}</h1>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Sidebar;
