import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Main from './pages/Main';
import Login from './pages/Login'; 
import { AppContext } from './components/AppContext';

function App() {
  const {isLoggedIn,setIsLoggedIn,setUser} = useContext(AppContext)
 
 
  useEffect(() => {
    const user = localStorage.getItem('user');
    if (user) {
      setIsLoggedIn(true);
      setUser(user);
    }
  }, [setIsLoggedIn, setUser]);
  const PrivateRoute = ({ element }) => (
    isLoggedIn ? element : <Navigate to="/" />
  );

  return (
   
      <Routes>
        <Route path="/main" element={<PrivateRoute element={<Main />} />} />
        <Route path="/" element={<Login />} />
      </Routes>
  
  );
}

export default App;
