import React, { useEffect, useState } from 'react';
import Modal from './Modal';
import { getStorage, ref, deleteObject, listAll, getDownloadURL, getMetadata  } from 'firebase/storage';
import { app } from '../firebase/Firebase';
import { toast } from 'react-toastify';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { FaTrash } from "react-icons/fa6";
import { IoEye } from "react-icons/io5";
function Mainsection({ files, folderName, handleFolderChange }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImagesModal, setSelectedImagesModal] = useState(false);
  const [selectedRow,setSelectedRow] = useState(null) 
  const storage = getStorage(app);
  const [metaData,setMetaData] = useState({})
  const storageURL = process.env.REACT_APP_STORAGE_URL;
  const [filesMetadata, setFilesMetadata] = useState({});
  const serverURL = process.env.REACT_APP_SERVER
  
  const openModal = (imageUrl) => {
    setSelectedImage(imageUrl);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setIsModalOpen(false);
  };
  const formatSize = (bytes) => {
    if (bytes >= 1073741824) {
      return `${(bytes / 1073741824).toFixed(2)} GB`;
    } else if (bytes >= 1048576) {
      return `${(bytes / 1048576).toFixed(2)} MB`;
    } else if (bytes >= 1024) {
      return `${(bytes / 1024).toFixed(2)} KB`;
    } else {
      return `${bytes} bytes`;
    }
  };
  useEffect(() => {
    const getImageMetadata = async () => {
      if (selectedRow) {
        const listRef = ref(storage, folderName);
        
        try {
          const res = await listAll(listRef);
          const files = res.items;
          
          for (const file of files) {
            if (file.name === selectedRow) {
              const metadata = await getMetadata(file);
            
              setMetaData(metadata)
             
            }
          }
          
    
        } catch (error) {
      
        }
      }
    };
  
    getImageMetadata();
  }, [selectedRow]);
  
  const handleDownloadAll = async () => {
    setSelectedImagesModal(false);
    setIsLoading(true);
    let retryCount = 0;
    const maxRetries = 3; // Maximum number of retries
  
    while (retryCount < maxRetries) {
      try {
        const currentDate = new Date().toISOString().slice(0, 10);
        const timestamp = Date.now(); // Current timestamp in milliseconds
        const randomString = Math.random().toString(36).substring(7); // Generate a random string
  
        const selectedImagesString = selectedImages.join(',');
  
        const response = await fetch(`${serverURL}/downloadAll?selectedImages=${selectedImagesString}&folderName=${folderName}`);
        if (!response.ok) {
          throw new Error('Failed to download files');
        }
  
        const blob = await response.blob();
  
        if (!blob.size) {
          throw new Error('Empty blob received');
        }
  
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${folderName}_${timestamp}_${randomString}_${currentDate}.zip`; // Include timestamp and random string
        a.click();
        window.URL.revokeObjectURL(url);
  
        toast.success("Download success");
        setIsLoading(false);
        setSelectedImages([]);
        return; // Exit the function after successful download
      } catch (error) {
        retryCount++;
        console.error(`Error downloading files (Attempt ${retryCount}):`, error);
        if (retryCount === maxRetries) {
          toast.error("Failed to download files after multiple attempts");
          setIsLoading(false);
          return; // Exit the function after max retries
        }
        await new Promise(resolve => setTimeout(resolve, 1000)); // Wait for 1 second before retrying
      }
    }
  };









  const handleDeleteAll = async () => {
    setSelectedImagesModal(false)
    setIsLoading(true);
    
  
    try {
      for (const file of selectedImages) {
        const fileRef = ref(storage, `${folderName}/${file}`);
        await deleteObject(fileRef);
      }
      
      
      setIsLoading(false);
      handleFolderChange(folderName);
      toast.success('All selected files deleted successfully');
      setSelectedImages([]); 
      setSelectedImagesModal(false); 
      refreshTable(); 
    } catch (error) {
      console.error('Error deleting files:', error);
      setIsLoading(false);
    }
  };


  useEffect(()=>{
    setSelectedRow(null)
    setSelectedImages([])
  },[folderName])

 
  const handleDelete = async (fileName) => {
    setIsDeleteConfirmOpen(false);
    setIsLoading(true);
    const fileRef = ref(storage, `${folderName}/${fileName}`);
    try {
      await deleteObject(fileRef);
     
      setIsLoading(false);
      handleFolderChange(folderName);
      toast.success('deleted successfully');
      refreshTable();
      setSelectedImages([])
      setSelectedRow(null)
    } catch (error) {
      console.error('Error deleting file:', error);
      setIsLoading(false);
    }
  };

  const refreshTable = async () => {
    
    const listRef = ref(storage, folderName);
    try {
      const res = await listAll(listRef);
      const updatedFiles = res.items.map((itemRef) => itemRef.name);
    
    } catch (error) {
      console.error('Error refreshing table:', error);
    }
  };

  const handleCheckboxChange = (file) => {
    setSelectedImages(prevSelectedImages => {
      if (prevSelectedImages.includes(file)) {
        return prevSelectedImages.filter((image) => image !== file);
      } else {
        return [...prevSelectedImages, file];
      }
    });
  };
  const renderSelectedImagesText = () => {
    const count = selectedImages.length;
    return count > 0 ? `${count} image${count > 1 ? 's' : ''} selected` : '';
  };
  useEffect(() => {
    const getImageMetadata = async () => {
      const listRef = ref(storage, folderName);
      try {
        const res = await listAll(listRef);
        const files = res.items;
        const metadataPromises = files.map(file => getMetadata(file));
        
        const metadataArray = await Promise.all(metadataPromises);
        const metadataMap = {};
        metadataArray.forEach((metadata, index) => {
          metadataMap[files[index].name] = metadata;
        });
        
        setFilesMetadata(metadataMap);
      } catch (error) {
        console.error('Error fetching metadata:', error);
      }
    };

    getImageMetadata();
  }, [folderName]);

  const handleKeyDown = (event) => {
    if (selectedRow) {
      switch (event.key) {
        case 'ArrowUp':
          moveSelectedRow(-1); // Move up
          break;
        case 'ArrowDown':
          moveSelectedRow(1); // Move down
          break;
        case ' ':
          event.preventDefault();
  
          const checkbox = document.getElementById(`checkbox-${selectedRow}`);
          
          if (checkbox) {
            checkbox.checked = !checkbox.checked;
            
            handleCheckboxChange(selectedRow);
          }
  
        
          
          break;
        default:
          break;
      }
    }
  };
  
  
  const moveSelectedRow = (direction) => {
    const sortedFiles = files
      .map((file, index) => ({
        file,
        metadata: filesMetadata[file] || { timeCreated: null },
      }))
      .sort((a, b) => new Date(b.metadata.timeCreated) - new Date(a.metadata.timeCreated))
      .map((item) => item.file);
  
    const newIndex = sortedFiles.indexOf(selectedRow) + direction;
  
    if (newIndex >= 0 && newIndex < sortedFiles.length) {
      setSelectedRow(sortedFiles[newIndex]);
    }
  };
  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedRow,files]);

  const renderSelectedImagesModal = () => {
    return (
      <div className="fixed top-4  left-0 z-50 w-full h-full flex items-center justify-center bg-black bg-opacity-75 overflow-y-auto">
        <div className="bg-white p-4 rounded-md overflow-y-auto max-w-screen-lg">
          <div className="flex justify-between mb-4">
            <h2 className="text-xl font-semibold">{renderSelectedImagesText()}</h2>
           
          </div>
          <div className="grid grid-cols-1 md:grid-cols-5 gap-4">
            {selectedImages.map((file, index) => (
              <div key={index}>
                <img
                  src={`https://firebasestorage.googleapis.com/v0/b/${storageURL}/o/${encodeURIComponent(folderName)}%2F${encodeURIComponent(file)}?alt=media&token=4fb52a6d-15c4-4961-afd6-5fcc47d81356`}
                  alt={file}
                  className="w-full h-40 object-contain rounded-md cursor-pointer"
                 
                />
                
              </div>
            ))}
            
          </div>
           <div className="w-full flex flex-col md:flex-row justify-between mt-12 items-center md:justify-between gap-4 ">
                   <div>
                   <button className="text-yellow-100 text-sm bg-black font-bold  px-4 py-2 rounded-xl" onClick={handleDownloadAll}>
                         Download
                  </button>

                   </div>
                    
                  <div className='flex flex-col md:flex-row gap-4'> 
                         <div>
                             <button className="text-white text-sm bg-red-600 px-4 py-2 rounded-xl"  onClick={handleDeleteAll}>Delete</button>
                         </div>
                         <div>
                         <button className="text-black text-sm bg-orange-300 px-4 py-2 rounded-xl " onClick={() => setSelectedImagesModal(false)}>Close</button>
                         </div>
                       
                       
                  </div>
                 
            </div>
        </div>
      </div>
    );
  };

  return (
    <div className="p-4 bg-white h-screen overflow-y-auto ">
      {isLoading && (
        <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="border-t-4 border-b-4 border-orange-500 rounded-full w-12 h-12 animate-spin"></div>
          <div className="ml-4 text-white">Loading...</div>
        </div>
      )}

      {isModalOpen && (
        <Modal imageUrl={selectedImage} onClose={closeModal} onDelete={() => handleDelete(selectedImage)} />
      )}

      {isDeleteConfirmOpen && (
        <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-md shadow-lg">
            <p className="text-lg mb-4">Are you sure you want to delete this image?</p>
            <div className="flex justify-end">
              <button className="bg-red-500 text-white px-4 py-2 mr-2" onClick={() => handleDelete(selectedImage)}>
                Delete
              </button>
              <button className="bg-gray-300 text-black px-4 py-2" onClick={() => setIsDeleteConfirmOpen(false)}>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}

      {selectedImages.length > 0 && (
        <>
          <div className="fixed bottom-0 right-0 z-50 m-4 p-4 bg-black rounded-md shadow-3xl">
            <button className="text-white font-bold " onClick={() => setSelectedImagesModal(true)}>View Selected {selectedImages.length}</button>
          </div>
          {selectedImagesModal && renderSelectedImagesModal()}
        </>
      )}

        <div className="grid grid-cols-3">
             <div className='col-span-3 lg:col-span-2'>
             {files.length === 0 ? (<><h1 className='text-center text-lg'>Please select a folder to get started or upload image in the folder</h1> <h1 className='text-3xl text-center mt-2 animate-bounce'>😊</h1></>):
              (
                <table className=" border-collapse border border-gray-300 w-full ">
                <thead>
                  <tr>
                    <th className="border border-gray-300  "></th>
                    <th className="border border-gray-300 px-4 py-2">Name</th>
                    <th className="border border-gray-300 px-4 py-2">Actions</th>
                  </tr>
                </thead>
                  <tbody>
                  {
                        files
                          // Filter and sort files locally
                          .filter(file => filesMetadata[file]) // Filter out files that don't have metadata
                          .sort((a, b) => 
                            new Date(filesMetadata[b].timeCreated) - new Date(filesMetadata[a].timeCreated)
                          )
                          .map((file, index) => ({
                            file,
                            metadata: filesMetadata[file],
                          }))
                          .map(({ file, metadata }, index) => (
                            <tr 
                              key={index} 
                              className={`border border-gray-300 ${selectedRow === file ? 'bg-gray-200' : ''}`}
                              onClick={() => { setSelectedRow(file) }}
                            >
                              <td className='border border-gray-300  py-1 w-8'> {/* Reduced padding here */}

                                <input 
                                  id={`checkbox-${file}`} // Add an ID to the checkbox
                                  type='checkbox'  
                                  className='ml-2 '
                                  checked={selectedImages.includes(file)}
                                  onChange={() => handleCheckboxChange(file)}
                                />
                              </td>
                              <td className="border border-gray-300 px-4 py-1 break-words  ">{file}</td> {/* Reduced padding here */}
                            
                              <td className="border border-gray-300 px-4 py-1 w-24"> {/* Reduced padding here */}
                                  <div className='flex'>
                                        <button 
                                          className="hover:text-black text-green-500 px-2 py-1  mb-2 md:mb-0"
                                          onClick={() => openModal(`https://firebasestorage.googleapis.com/v0/b/${storageURL}/o/${encodeURIComponent(folderName)}%2F${encodeURIComponent(file)}?alt=media&token=4fb52a6d-15c4-4961-afd6-5fcc47d81356`)}
                                            >
                                            <IoEye />
                                          </button>
                                          <button 
                                            className=" hover:text-black text-red-800 px-2 py-1 mb-2 md:mb-0"
                                            onClick={() => {
                                              setSelectedImage(file);
                                              setIsDeleteConfirmOpen(true);
                                            }}
                                          >
                                            <FaTrash/>
                                      </button>
                                  </div>
                                  
                              </td>
                            </tr>
                          ))
                      }
                     </tbody>
                  </table>
              )
             }
             
                  </div>
                <div className='ml-3  col-span-1  hidden xl:block md:hidden sm:hidden w-full'>
                  {selectedRow ? (
                    <div className='flex-col flex border h-[90vh] p-2 fixed '>
                      <img 
                        src={`https://firebasestorage.googleapis.com/v0/b/${storageURL}/o/${encodeURIComponent(folderName)}%2F${encodeURIComponent(selectedRow)}?alt=media&token=4fb52a6d-15c4-4961-afd6-5fcc47d81356`} 
                        alt={selectedRow} 
                        className='object-contain w-[40vh]  h-[40vh]'
                      />
                    <div className='flex flex-row justify-between border-b pb-4 mt-10 '>
                        <h1 className='text-sm mt-4 font-semibold'>
                            Name:
                        </h1>
                        <h1 className='text-sm mt-4 font-bold overflow-hidden whitespace-nowrap overflow-ellipsis'>
                            {selectedRow}
                        </h1>
                    </div>
                      <div className='flex flex-row justify-between  border-b pb-4'>
                      <h1 className='text-sm mt-4 font-semibold'>
                        Date:
                      </h1>
                      <h1 className='text-sm mt-4 font-bold'>
                      {metaData?.timeCreated ? new Date(metaData.timeCreated).toLocaleString() : 'Date not available'}
                      </h1>
                      </div>
                      <div className='flex flex-row justify-between  border-b pb-4'>
                      <h1 className='text-sm mt-4 font-semibold'>
                        Size:
                      </h1>
                      <h1 className='text-sm mt-4 font-bold'>
                      {formatSize(metaData?.size)}
                      </h1>
                      </div>
                    </div>
                  ) : null}
                </div>

    </div>
    </div>
  );
}

export default Mainsection;
