// LoadingModal.jsx
import React from 'react';

function LoadingModal() {
  return (
    <div className="fixed top-0 left-0 z-50 w-full h-full flex items-center justify-center bg-black bg-opacity-50">
      <div className="border-t-4 border-b-4 border-orange-500 rounded-full w-12 h-12 animate-spin"></div>
      <div className="ml-4 text-white">Loading...</div>
    </div>
  );
}

export default LoadingModal;
