import { createContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";


export const AppContext = createContext()

export const AppContextProvider   = ({children}) =>{
    const navigate = useNavigate()
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [user,setUser] = useState([])
    useEffect(() => {
        const x = localStorage.getItem('user');
        if (x) {
          setIsLoggedIn(true);
          setUser(user);
          navigate("/main")
        }
      }, []);
    return(
        <AppContext.Provider value={{isLoggedIn,setIsLoggedIn,user,setUser}}>
            {children}
        </AppContext.Provider>
    )
}
